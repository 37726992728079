import React from 'react';
import Header from './components/Header';
import './App.css';

class App extends React.Component {
  render() {
    return (
      <div className='App'>
        <div className='c-preloader  js-preloader'>
          <div className='c-preloader__spinner  t-preloader__spinner'></div>
        </div>

        <div className='c-main-container  js-main-container'>
          <Header />
        </div>
      </div>
    );
  }
}

export default App;
