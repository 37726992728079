import React from 'react';

const Header = () => {
  return (
    <section className='o-section o-section--header  t-section  t-section--header'>
      <div className='c-header'>
        <div className='o-section__header-bg  t-section__header'></div>
        <div className='o-section__content-bg  t-section__content'></div>

        <div className='o-container'>
          <div className='o-section__container'>
            <header className='o-section__header  c-header__header  t-section__header'>
              <div className='c-header__inner-header'>
                <div className='c-header__avatar'>
                  <div className='a-header  c-avatar'>
                    <img
                      className='c-avatar__img'
                      src='images/header/avatar.jpg'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </header>

            <div className='o-section__content  c-header__content  t-section__content'>
              <div className='c-header__inner-content'>
                <div className='c-header__top'>
                  <div className='c-header__brand'>
                    <div className='c-brand'>
                      <h1 className='c-brand__title  t-title'>
                        <span className='c-brand__sizer'>
                          <span className='a-header  c-brand__first-word  t-title__first-word'>
                            Robert
                          </span>
                          <span className='a-header  c-brand__second-word  t-title__second-word'>
                            Kogami
                          </span>
                        </span>
                      </h1>
                      <h2 className='a-header  c-brand__sub-title  t-sub-title'>
                        <span className='c-brand__sizer'>
                          Systems Engineer, Web Developer &amp; Hobbyist
                          Programmer
                        </span>
                      </h2>
                    </div>
                  </div>

                  <ul className='c-header__social-buttons  c-social-buttons'>
                    <li className='a-header'>
                      <a
                        href='https://www.linkedin.com/in/robertkogami'
                        target='_blank'
                        rel='noreferrer'
                        className='c-social-button  t-social-button'
                      >
                        <i className='fab  fa-lg  fa-linkedin'></i>
                      </a>
                    </li>
                    <li className='a-header'>
                      <a
                        href='https://github.com/robertkogami'
                        target='_blank'
                        rel='noreferrer'
                        className='c-social-button  t-social-button'
                      >
                        <i className='fab  fa-lg  fa-github'></i>
                      </a>
                    </li>
                    <li className='a-header'>
                      <a
                        href='https://twitter.com/robehk'
                        target='_blank'
                        rel='noreferrer'
                        className='c-social-button  t-social-button'
                      >
                        <i className='fab  fa-lg  fa-twitter'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
